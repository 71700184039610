import { createSlice } from "@reduxjs/toolkit";
import { USER } from "../components/common/consts";

const modifyUser = (data) => {
  data.nickname = "@" + data.nickname;
  return data;
};
const commonSlice = createSlice({
  name: "user",
  initialState: {
    user: modifyUser(USER) || {},
    someoneAvatar: {},
  },
  reducers: {
    setMainUser(state, newAction) {
      state.user = newAction.payload;
      window.startConfig = { ...(window.startConfig || {}), user: newAction.payload };
    },
    setAvatarUser(state, newAction) {
      state.user.avatar = newAction.payload;
    },
    setBalance(state, newAction) {
      state.user.balance = newAction.payload;
    },
    setPremium(state, newAction) {
      state.user.premium = newAction.payload;
    },
    setFavorites(state, newAction) {
      state.user.favorites = newAction.payload;
    },
    setSomeoneAvatar(state, newAction) {
      const { id, avatar } = newAction.payload;
      state.someoneAvatar[id] = avatar;
    },
  },
});

export default commonSlice.reducer;

export const {
  setMainUser,
  setAvatarUser,
  setBalance,
  setSomeoneAvatar,
  setFavorites,
  setPremium,
} = commonSlice.actions;
