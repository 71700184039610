import { useSelector } from "react-redux";
import { useLocalStorage } from "usehooks-ts";
import { ENUM_LOCALSTORAGE } from "../components/common/enums";
import useJanus from "./useJanus";
import usePaidAction from "./usePaidAction";

export default function useTips() {
  const { postTips, postLovenseTips } = useJanus({});
  const { onTipsClick } = usePaidAction();

  const tips = useSelector((state) => state.chat.tips);
  const tipsMenu = useSelector((state) => state.chat.tipsMenu);
  const defaultTips = { amount: tips?.[0]?.value, ...tips[0] } || { amount: 50 };

  const [currentToken, setCurrentToken] = useLocalStorage(
    ENUM_LOCALSTORAGE.lastAmount,
    defaultTips
  );

  const amount = currentToken.amount;

  return {
    currentToken,
    tips,
    amount,
    tipsMenu,
    setCurrentToken,
    postLovenseTips,
    postTips,
    onTipsClick,
  };
}
