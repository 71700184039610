export const SIGN_UP = "Sign up";
export const LOGIN = "Login";
export const SITE_NAME = "RichCams";
export const MAX_SCALE = 6;
export const isBonusForNewUsers = false;

export const TEST_MODE =
  window.location.hostname === "localhost" ||
  window.location.hostname.startsWith("192.168.") ||
  window.location.hostname.startsWith("10.");

const startConfig = window.startConfig;
export const configSetting = startConfig?.settings;

export const IS_SKIP_INTO = configSetting?.url?.find((el) => el.path.includes("skip-intro"))?.value;
export const CAMPAIGN_ID = configSetting?.url?.find((el) => el.path.includes("campaign_id"))?.value;
export const CAPTCHA = startConfig?.settings?.captcha;

const TEST_USER = {
  id: 5,
  // id: 0,
  registered: "2023-10-28 20:10:29",
  nickname: "dirahi4449",
  avatar: "/var/www/vhosts/dev.cam4fan.com/httpdocs/gallery/181/avatar/",
  type: "client",
  favorites: [6, 6, 6, 6, 6, 6, 6, 6, 4, 5, 6],
  premium: false,
  group: {
    id: 2,
    group_id: 1,
    enabled: true,
    title: "Webcam Sites",
    balance: 0,
    rights: [],
  },
  profile: [],
  status: "unconfirmed",
  balance: 9999999990,
  email: "dirahi4449@wanbeiz.com",
  email_confirmed: true,
  confirmed: true,
  personal_information_confirmed: false,
  approve_status: "unconfirmed",
  rights: [],
};

export const USER = TEST_MODE ? TEST_USER : startConfig?.user || {};
export const SITE_KEY = CAPTCHA?.["site-key"];
export const debugLog = true;

export const OPEN_PATH_NAMES = {
  confirmEmail: "/client/email/confirm",
  emailChange: "/client/email/change",
  passwordReset: "/client/password/reset",
  P2257: "/2257",
  dmca: "/dmca",
  contact: "/contact",
  cookie: "/cookie",
  policy: "/policy",
  about: "/about",
  terms: "/terms",
  checkOrder: "/payment/:orderId/check",
};

export const ENUM_CUS_EVENT = {
  getSettings: "get.settings",
  updateLanguage: "update.language",
  currentPremium: "current.premium",
  responseSwitch: "response.switch",
  cbAuth: "cb.auth",
};

export const ENUM_TOAST_TYPE = {
  success: "success",
  error: "error",
  info: "info",
};

export const typeByVibroLevel = {
  1: "low",
  2: "low",
  3: "low",
  4: "low",
  5: "low",
  6: "medium",
  7: "medium",
  8: "medium",
  9: "medium",
  10: "medium",
  11: "high",
  12: "high",
  13: "high",
  14: "high",
  15: "high",
  16: "ultra-high",
  17: "ultra-high",
  18: "ultra-high",
  19: "ultra-high",
  20: "ultra-high",
};

export const vibroTextByLevel = {
  1: "Low",
  2: "Low",
  3: "Low",
  4: "Low",
  5: "Low",
  6: "Medium",
  7: "Medium",
  8: "Medium",
  9: "Medium",
  10: "Medium",
  11: "High",
  12: "High",
  13: "High",
  14: "High",
  15: "High",
  16: "Ultra High",
  17: "Ultra High",
  18: "Ultra High",
  19: "Ultra High",
  20: "Ultra High",
};
