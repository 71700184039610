import { createSlice } from "@reduxjs/toolkit";
import { LSM_SIZE, MOBILE_HEADER, MOBILE_SIZE } from "./enums";

export const EnumDeviceModes = {
  mobile: "mobile",
  desktop: "desktop",
};
export const ENUM_DEVICE_ORIENTATION = {
  portrait: "portrait",
  landscape: "landscape",
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
const isAndroid = navigator.userAgent.search("Android") >= 0;
const isWebRTC = !!(
  navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mozGetUserMedia ||
  navigator.msGetUserMedia
);

const checkAppMode = (width = window.innerWidth, angle = screen?.orientation?.angle) => {
  if (angle == 90) return width <= MOBILE_SIZE ? EnumDeviceModes.mobile : EnumDeviceModes.desktop;

  return width <= MOBILE_SIZE ? EnumDeviceModes.mobile : EnumDeviceModes.desktop;
};

const getOrientation = (angle = screen?.orientation?.angle) => {
  if (angle === 90) return ENUM_DEVICE_ORIENTATION.landscape;
  if (angle === 0) return ENUM_DEVICE_ORIENTATION.portrait;
};

const commonSlice = createSlice({
  name: "deviceSize",
  initialState: {
    appMode: checkAppMode(),
    width: window.innerWidth,
    fullWidth: window.outerWidth,
    isMobileSize: window.innerWidth < MOBILE_SIZE,
    isMobileHeader: window.innerWidth < MOBILE_HEADER,
    isLsm: window.innerWidth < LSM_SIZE,
    isMobile: checkAppMode() === EnumDeviceModes.mobile,
    height: window.innerHeight,
    isSafari: isSafari,
    isIos: isIos,
    isAndroid: isAndroid,
    isIphone: checkAppMode() === EnumDeviceModes.mobile && isIos,
    isWebRTC: isWebRTC,
    orientation: getOrientation(),
    angle: screen?.orientation?.angle,
  },
  reducers: {
    setWidth(state, newAction) {
      const width = newAction.payload;
      state.appMode = checkAppMode(width);
      state.isMobile = checkAppMode(width) === EnumDeviceModes.mobile;
      state.width = width;
      state.isMobileHeader = width < MOBILE_HEADER;
      state.isMobileSize = width < MOBILE_SIZE;
      state.isLsm = width < LSM_SIZE;
    },
    setAngle(state, newAction) {
      const angle = newAction.payload;
      state.angle = angle;
      state.orientation = getOrientation(angle);
      state.appMode = checkAppMode(window.innerWidth, angle);
      state.isMobile = checkAppMode(window.innerWidth, angle) === EnumDeviceModes.mobile;
    },
    setFullWidth(state, newAction) {
      state.fullWidth = newAction.payload;
    },
  },
});

export default commonSlice.reducer;

export const { setWidth, setAngle, setFullWidth } = commonSlice.actions;
