export const ENUM_LOCALSTORAGE = {
  autostart: "autostart",
  cameraId: "cameraId",
  devices: "devices",
  localCamera: "localCamera",
  localMicro: "localMicro",
  localResolution: "localResolution",
  lastAmount: "lastAmount",
  confirmAge: "confirmAge",
  haveAccount: "haveAccount",
  cameraPerm: "cameraPermission",
  typeModel: "typeModel",
  typeSort: "typeSort",
  shown: "shown",
  filter: "filter",
  isRestore: "isRestore",
};
