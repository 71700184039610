import { API_CHAT } from "../../../api/chat";
import { debugLog } from "../../../components/common/consts";
import { errorToast, infoToast } from "../../../components/mui/Toaster";
import {
  ENUM_CHAT_MODE,
  modeByStatus,
  setChatActiveMode,
  setSettingInfo,
  setStatusWindow,
  setViewPrice,
  setViewTime,
} from "../../../store/chatSlice";
import JanusState from "./JanusState";

export default class JanusStatuses {
  joined; // объект с информацией о присоединении
  dispatch; // функция обновления стора из редакс
  broadcasterId; // ид модели/чата

  constructor({ dispatch, broadcasterId }) {
    this.dispatch = dispatch;
    this.broadcasterId = broadcasterId;
  }

  // infoToast - вывод информации в тост
  infoToast(res) {
    if (res?.data?.message) infoToast(res.data.message);
  }

  // setUserMode - установка мода пользователя для тарификации
  async setUserMode(mode) {
    const clientChat = JanusState.clientChat;
    const chatId = clientChat.chatId;
    const participantId = clientChat.localfeed.id; //

    try {
      const res = await API_CHAT.setUserMode(chatId, mode, participantId);
      this.infoToast(res);
    } catch (e) {
      if (typeof e === "string") errorToast(e);
    }
  }

  // frontLogic - логика фронта по режиму чата
  frontLogic(mode) {
    const currentMode = modeByStatus[mode];
    if (!currentMode) return;

    console.log("status ", currentMode);

    this.dispatch(setStatusWindow(currentMode));
    this.dispatch(setChatActiveMode(mode));
  }

  // getSetting - получаем текущий статус с сервера
  async getSetting() {
    try {
      if (!JanusState.clientChat.chatId) {
        if (debugLog) errorToast("No chat Id on getSetting");
        return;
      }

      const resSetting = await API_CHAT.setting(JanusState.clientChat.chatId);
      this.infoToast(resSetting);

      const settings = resSetting.data?.settings;
      if (!settings) return;
      this.dispatch(setSettingInfo(settings));

      const type = settings.type;
      const billing = settings[type]?.billing;

      this.dispatch(setViewTime(+(billing?.time?.value / 60).toFixed(1) || 0));
      this.dispatch(setViewPrice(billing?.price?.value || 0));
      return resSetting;
    } catch (e) {
      console.log(e);
    }
  }

  // change - функция изменения режима чата
  async change(mode) {
    const id = this.joined.id;
    const chatId = JanusState.clientChat?.chatId;

    // if (mode !== JanusState.modelMode) return;

    console.log(id, chatId);

    try {
      if (!id || !chatId) return false;

      // TODO need fix code

      let resMode;
      if (mode === ENUM_CHAT_MODE.private) {
        resMode = await API_CHAT.setMode.private(chatId);
      } else if (mode === ENUM_CHAT_MODE.exclusive) {
        resMode = await API_CHAT.setMode.exclusive(chatId);
      } else if (mode === ENUM_CHAT_MODE.free) {
        resMode = await API_CHAT.setMode.free(chatId);

        if (resMode) {
          const res = await this.setUserMode(mode);
          this.infoToast(res);
        }
      } else if (mode === ENUM_CHAT_MODE.pause) {
        resMode = await API_CHAT.setMode.pause(chatId);

        if (resMode) {
          const res = await this.setUserMode(mode);
          this.infoToast(res);
        }
      } else if (mode === ENUM_CHAT_MODE.paid) {
        resMode = await this.setUserMode(mode);
      } else if (mode === ENUM_CHAT_MODE.view) resMode = await this.setUserMode(mode);

      this.infoToast(resMode);

      return true;
    } catch (e) {
      console.log(e);
      if (typeof e === "string") errorToast(e);
      // this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.offline));
      return false;
    }
  }
}
