import { useCallback } from "react";
import { ENUM_MODALS_VALUE, setModals } from "../store/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../components/mui/Toaster";
import { useTranslation } from "react-i18next";
import { ENUM_CHAT_MODE, ENUM_STATUS_WINDOW } from "../store/chatSlice";
import WebcamState from "../controls/WebcamState";

export const ENUM_PAID_ACTION = {
  price: "price",
};

// TODO завтра проверить
export default function usePaidAction() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: userId, balance } = useSelector((state) => state.user.user);
  const viewPrice = useSelector((state) => state.chat.viewPrice);
  const status = useSelector((state) => state.chat.statusWindow);
  const chatActiveTab = useSelector((state) => state.chat.chatActiveTab);

  const onPaidActionClick = useCallback(
    (joinPrice, cb) => {
      WebcamState.lastJoinPrice = joinPrice;

      if (joinPrice === ENUM_PAID_ACTION.price) joinPrice = viewPrice;

      if (!userId) {
        dispatch(setModals({ name: ENUM_MODALS_VALUE.typeLogin, value: true }));
        WebcamState.authCb = cb;
        return;
      }

      if ((balance || 0) < +joinPrice) {
        dispatch(setModals({ name: ENUM_MODALS_VALUE.deposit, value: true }));
        return;
      }

      console.log("cb", cb);

      cb();
    },
    [userId, balance]
  );

  const onTipsClick = useCallback(
    (cb) => {
      const availableMes = status === ENUM_STATUS_WINDOW.stream;

      if (!userId) {
        dispatch(setModals({ name: ENUM_MODALS_VALUE.typeLogin, value: true }));
        return;
      }

      if (!availableMes) {
        errorToast(t(`The model is in ${status}, you cannot send messages yet.`));
        return;
      }

      if (chatActiveTab === ENUM_CHAT_MODE.view) {
        errorToast(t("You cannot send messages."));
        return;
      }

      cb();
    },
    [userId, status, chatActiveTab]
  );

  return { onPaidActionClick, onTipsClick };
}
