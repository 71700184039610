import { dispatchCusEvent, getShortLang } from "../../utils/utils";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { ENUM_CUS_EVENT } from "../../components/common/consts";

export const ENUM_LANG_LISTENER = {
  auto: "auto",
};

export default function useLanguage() {
  const shortLang = getShortLang();
  const languages = useSelector((state) => state.common.languages);
  const chosenLang = languages.find((el) => el.code === shortLang)?.lang;

  const [{ language: cookieLang }, setCookieLang] = useCookies(["language"]);

  useEffect(() => {
    // если в куке есть, то больше не заполняем
    if (cookieLang && cookieLang !== "undefined") return;

    setCookieLang("language", chosenLang, { path: "/" });
  }, [cookieLang, chosenLang]);

  useEffect(() => {
    dispatchCusEvent(ENUM_CUS_EVENT.updateLanguage, { cookie: cookieLang });
  }, [cookieLang]);

  return [cookieLang, (lang) => setCookieLang("language", lang, { path: "/" })];
}
