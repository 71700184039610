/**
 * глобальный стор сайта
 */
export default class WebcamState {
  // функция которая выполница после входа
  static authCb = () => {};

  // цена для подключения к чату
  static lastJoinPrice = 0;

  // текущий экземпляр библиотеки для увеличения
  static zoomist = {};
}
